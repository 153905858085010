
<template>
  <div>
    <!-- faq search section -->

    <section id="card-text-alignment">
      <div>
        <h5
          class="mt-3 mb-2 d-flex justify-content-start font-weight-bolder text-dark"
          style="
            font-weight: 600;
            font-size: 26px;
            font-family: 'Public Sans', sans-serif;
          "
        >
          Settings
        </h5>
      </div>
      <b-row>
        <!-- left align -->
          <b-col
            md="6"
            lg="4"
            class="d-flex justify-content-center align-items-center"
          >
            <b-card
              class="text-center settings-icon"
            >
              <div
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <svg
                  class="mt-3"
                  width="58"
                  height="49"
                  viewBox="0 0 58 49"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M43.5901 30.54C43.3201 30.51 43.0501 30.49 42.7701 30.49C42.2801 30.49 41.8001 30.54 41.3401 30.64C36.7501 31.23 32.5101 34.19 29.2801 39.13L28.8101 39.84L29.1701 40.39C32.8001 45.94 37.6901 49 42.9501 49C48.2001 49 53.1001 45.94 56.7301 40.39L57.2001 39.68L56.8401 39.13C53.3201 33.77 48.6401 30.74 43.5901 30.54ZM47.6701 37.4C47.6701 40.11 45.4701 42.31 42.7601 42.31C40.0501 42.31 37.8501 40.11 37.8501 37.4C37.8501 35.06 39.5001 33.1 41.7001 32.61C42.1501 32.56 42.6001 32.52 43.0501 32.52C43.0701 32.52 43.1001 32.52 43.1201 32.52C45.6601 32.72 47.6701 34.82 47.6701 37.4ZM42.9501 47C38.5601 47 34.4101 44.46 31.2101 39.84C32.7701 37.55 34.5601 35.77 36.4901 34.54C36.0901 35.41 35.8601 36.38 35.8601 37.4C35.8601 41.21 38.9601 44.31 42.7701 44.31C46.5801 44.31 49.6801 41.21 49.6801 37.4C49.6801 36.18 49.3601 35.04 48.8101 34.05C51.0101 35.26 53.0501 37.16 54.8101 39.69C51.5801 44.41 47.3801 47 42.9501 47Z"
                    fill="#4B465C"
                  />
                  <path
                    d="M43.5901 30.54C43.3201 30.51 43.0501 30.49 42.7701 30.49C42.2801 30.49 41.8001 30.54 41.3401 30.64C36.7501 31.23 32.5101 34.19 29.2801 39.13L28.8101 39.84L29.1701 40.39C32.8001 45.94 37.6901 49 42.9501 49C48.2001 49 53.1001 45.94 56.7301 40.39L57.2001 39.68L56.8401 39.13C53.3201 33.77 48.6401 30.74 43.5901 30.54ZM47.6701 37.4C47.6701 40.11 45.4701 42.31 42.7601 42.31C40.0501 42.31 37.8501 40.11 37.8501 37.4C37.8501 35.06 39.5001 33.1 41.7001 32.61C42.1501 32.56 42.6001 32.52 43.0501 32.52C43.0701 32.52 43.1001 32.52 43.1201 32.52C45.6601 32.72 47.6701 34.82 47.6701 37.4ZM42.9501 47C38.5601 47 34.4101 44.46 31.2101 39.84C32.7701 37.55 34.5601 35.77 36.4901 34.54C36.0901 35.41 35.8601 36.38 35.8601 37.4C35.8601 41.21 38.9601 44.31 42.7701 44.31C46.5801 44.31 49.6801 41.21 49.6801 37.4C49.6801 36.18 49.3601 35.04 48.8101 34.05C51.0101 35.26 53.0501 37.16 54.8101 39.69C51.5801 44.41 47.3801 47 42.9501 47Z"
                    fill="white"
                    fill-opacity="0.3"
                  />
                  <path
                    d="M37 22.9998C37 22.6598 36.83 22.3398 36.54 22.1598L25.54 15.1598C25.23 14.9598 24.84 14.9498 24.52 15.1298C24.2 15.2998 24 15.6298 24 15.9998V29.9998C24 30.3698 24.2 30.6998 24.52 30.8798C24.67 30.9598 24.83 30.9998 25 30.9998C25.19 30.9998 25.37 30.9498 25.54 30.8398L36.54 23.8398C36.83 23.6598 37 23.3398 37 22.9998ZM26 28.1798V17.8198L34.14 22.9998L26 28.1798Z"
                    fill="#4B465C"
                  />
                  <path
                    d="M37 22.9998C37 22.6598 36.83 22.3398 36.54 22.1598L25.54 15.1598C25.23 14.9598 24.84 14.9498 24.52 15.1298C24.2 15.2998 24 15.6298 24 15.9998V29.9998C24 30.3698 24.2 30.6998 24.52 30.8798C24.67 30.9598 24.83 30.9998 25 30.9998C25.19 30.9998 25.37 30.9498 25.54 30.8398L36.54 23.8398C36.83 23.6598 37 23.3398 37 22.9998ZM26 28.1798V17.8198L34.14 22.9998L26 28.1798Z"
                    fill="white"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M57 0H47H11H1C0.45 0 0 0.45 0 1V12V23V34V45C0 45.55 0.45 46 1 46H11H26C26.55 46 27 45.55 27 45C27 44.45 26.55 44 26 44H12V34V23V12V2H46V12V23C46 23.55 46.45 24 47 24H56V32C56 32.55 56.45 33 57 33C57.55 33 58 32.55 58 32V23V12V1C58 0.45 57.55 0 57 0ZM2 24H10V33H2V24ZM10 22H2V13H10V22ZM2 44V35H10V44H2ZM10 11H2V2H10V11ZM56 2V11H48V2H56ZM48 22V13H56V22H48Z"
                    fill="#4B465C"
                  />
                  <path
                    d="M57 0H47H11H1C0.45 0 0 0.45 0 1V12V23V34V45C0 45.55 0.45 46 1 46H11H26C26.55 46 27 45.55 27 45C27 44.45 26.55 44 26 44H12V34V23V12V2H46V12V23C46 23.55 46.45 24 47 24H56V32C56 32.55 56.45 33 57 33C57.55 33 58 32.55 58 32V23V12V1C58 0.45 57.55 0 57 0ZM2 24H10V33H2V24ZM10 22H2V13H10V22ZM2 44V35H10V44H2ZM10 11H2V2H10V11ZM56 2V11H48V2H56ZM48 22V13H56V22H48Z"
                    fill="white"
                    fill-opacity="0.2"
                  />
                </svg>
                <div
                  v-if="isLoading"
                  style="height: 20px"
                  class="d-flex justify-content-center align-items-center my-1"
                >
                  <b-spinner label="Loading..."></b-spinner>
                </div>
                <h3
                  v-else
                  class="align-items-center font-weight-bolder my-1 count-class"
                >
                  {{ data.hide_video }}
                </h3>
                <!-- {{data && data.length>0 ?data[0].section :''}} -->
                <b-badge
                  @click="openHideVideo()"
                  class="cursor-pointer title-settings"
                  variant="light-primary"
                >
                  View all hide Videos
                </b-badge>
              </div>
            </b-card>
          </b-col>

          <b-col
            md="6"
            lg="4"
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <b-card
              class="text-center settings-icon"
              
            >
              <div
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <img src="../../../../../public/workout.svg" />
                <div
                  v-if="isLoading"
                  style="height: 20px"
                  class="d-flex justify-content-center align-items-center my-1"
                >
                  <b-spinner label="Loading..."></b-spinner>
                </div>
                <h3
                  v-else
                  class="align-items-center font-weight-bolder my-1 count-class"
                >
                  {{ data.suspended_client }}
                </h3>
                <!-- {{data && data.length>0 ?data[0].section :''}} -->
                <b-badge
                  @click="openSuspendedClient()"
                  class="cursor-pointer title-settings"
                  variant="light-primary"
                >
                  View all suspended users
                </b-badge>
              </div>
            </b-card>
          </b-col>

          <b-col
            md="6"
            lg="4"
            class="d-flex justify-content-center align-items-center"
          >
            <b-card
              class="text-center settings-icon"
            >
              <div
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <img src="../../../../../public/workout.svg" />
                <div
                  v-if="isLoading"
                  style="height: 20px"
                  class="d-flex justify-content-center align-items-center my-1"
                >
                  <b-spinner label="Loading..."></b-spinner>
                </div>
                <h3
                  v-else
                  class="align-items-center font-weight-bolder my-2 count-class"
                >
                  {{ data.suspended_trainer }}
                </h3>
                <!-- {{data && data.length>0 ?data[0].section :''}} -->
                <b-badge
                  @click="openSuspendedTrainer()"
                  class="cursor-pointer title-settings"
                  variant="light-primary"
                >
                  View all suspended trainers
                </b-badge>
              </div>
            </b-card>
          </b-col>


          <b-col
          md="6"
          lg="4"
          class="d-flex justify-content-center align-items-center"
          >
          <b-card
            class="text-center settings-icon"
          >
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <img src="../../../../../public/workout.svg" />
              <div
                v-if="isLoading"
                style="height: 20px"
                class="d-flex justify-content-center align-items-center my-1"
              >
                <b-spinner label="Loading..."></b-spinner>
              </div>
              <h3
                v-else
                class="align-items-center font-weight-bolder my-2 count-class"
              >
                {{ data.rejected_customer }}
              </h3>
              <!-- {{data && data.length>0 ?data[0].section :''}} -->
              <b-badge
                @click="$router.push('/settings/rejected-customers')"
                class="cursor-pointer title-settings"
                variant="light-primary"
              >
                View all rejected customer
              </b-badge>
            </div>
          </b-card>
        </b-col>
        <b-col
          md="6"
          lg="4"
          class="d-flex justify-content-center align-items-center"
          >
          <b-card
            class="text-center settings-icon"
          >
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <img src="../../../../../public/workout.svg" />
              <div
                v-if="isLoading"
                style="height: 20px"
                class="d-flex justify-content-center align-items-center my-1"
              >
                <b-spinner label="Loading..."></b-spinner>
              </div>
              <h3
                v-else
                class="align-items-center font-weight-bolder my-2 count-class"
              >
                {{ data.rejected_trainer }}
              </h3>
              <!-- {{data && data.length>0 ?data[0].section :''}} -->
              <b-badge
                @click="$router.push('/settings/rejected-trainer')"
                class="cursor-pointer title-settings"
                variant="light-primary"
              >
                View all rejected trainer
              </b-badge>
            </div>
          </b-card>
        </b-col>

          <b-col
            md="6"
            lg="4"
            class="d-flex justify-content-center align-items-center"
          >
            <b-card
              class="text-center settings-icon"
            >
              <div
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <svg
                  width="54"
                  height="61"
                  viewBox="0 0 54 61"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_229_11671)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.476766 34.726C-1.9627 16.535 7.26861 -0.00143005 27.0022 9.27572e-08C46.7341 0.00119188 56.1264 16.5701 53.5232 34.726H54C53.5596 42.6048 52.9054 48.0143 51.1852 50.0009V54.5069C51.1852 55.2812 50.5638 55.9151 49.8042 55.9151H48.677V57.1492C48.677 59.2656 46.4699 61 44.3929 61H44.0473V30.6514H44.3929C46.4684 30.6514 48.677 32.3857 48.677 34.5023V35.736H49.8042C50.5635 35.736 51.1852 36.3697 51.1852 37.1439V40.6518C51.7769 39.0949 51.9944 37.0291 52.0376 34.726H52.4443C53.4426 18.4294 43.7432 6.0818 27.0049 6.07918C10.2625 6.07644 0.557299 18.425 1.55571 34.726H1.96235C2.0056 37.0291 2.22312 39.0949 2.81478 40.6518V37.1439C2.81478 36.3697 3.43648 35.736 4.19575 35.736H5.32297V34.5023C5.32297 32.3857 7.5316 30.6514 9.60709 30.6514H9.95271V61H9.60709C7.53008 61 5.32297 59.2656 5.32297 57.1492V55.9151H4.19575C3.43625 55.9151 2.81478 55.2812 2.81478 54.5069V50.0009C1.09461 48.0143 0.440416 42.6048 0 34.726H0.476766Z"
                      fill="#4B465C"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.476766 34.726C-1.9627 16.535 7.26861 -0.00143005 27.0022 9.27572e-08C46.7341 0.00119188 56.1264 16.5701 53.5232 34.726H54C53.5596 42.6048 52.9054 48.0143 51.1852 50.0009V54.5069C51.1852 55.2812 50.5638 55.9151 49.8042 55.9151H48.677V57.1492C48.677 59.2656 46.4699 61 44.3929 61H44.0473V30.6514H44.3929C46.4684 30.6514 48.677 32.3857 48.677 34.5023V35.736H49.8042C50.5635 35.736 51.1852 36.3697 51.1852 37.1439V40.6518C51.7769 39.0949 51.9944 37.0291 52.0376 34.726H52.4443C53.4426 18.4294 43.7432 6.0818 27.0049 6.07918C10.2625 6.07644 0.557299 18.425 1.55571 34.726H1.96235C2.0056 37.0291 2.22312 39.0949 2.81478 40.6518V37.1439C2.81478 36.3697 3.43648 35.736 4.19575 35.736H5.32297V34.5023C5.32297 32.3857 7.5316 30.6514 9.60709 30.6514H9.95271V61H9.60709C7.53008 61 5.32297 59.2656 5.32297 57.1492V55.9151H4.19575C3.43625 55.9151 2.81478 55.2812 2.81478 54.5069V50.0009C1.09461 48.0143 0.440416 42.6048 0 34.726H0.476766Z"
                      fill="white"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M37.9109 39.6187C37.9109 39.0255 38.3779 38.5459 38.9555 38.5459C39.533 38.5459 40 39.0255 40 39.6187V53.3841C40 53.9773 39.533 54.457 38.9555 54.457C38.3779 54.457 37.9109 53.9773 37.9109 53.3841V39.6187ZM14 39.6187C14 39.0255 14.467 38.5459 15.0445 38.5459C15.6221 38.5459 16.0891 39.0255 16.0891 39.6187V53.3841C16.0891 53.9773 15.6221 54.457 15.0445 54.457C14.467 54.457 14 53.9773 14 53.3841V39.6187ZM18.0013 36.9451C18.0013 36.3548 18.4627 35.878 19.0292 35.878C19.5985 35.878 20.0572 36.3548 20.0572 36.9451V56.0577C20.0572 56.6481 19.5957 57.1249 19.0292 57.1249C18.46 57.1249 18.0013 56.6481 18.0013 56.0577V36.9451ZM21.9694 34.0728C21.9694 33.4797 22.4364 33 23.0139 33C23.5915 33 24.0585 33.4797 24.0585 34.0728V58.9272C24.0585 59.5203 23.5915 60 23.0139 60C22.4364 60 21.9694 59.5203 21.9694 58.9272V34.0728ZM33.9262 42.0312C33.9262 41.438 34.3932 40.9584 34.9708 40.9584C35.5483 40.9584 36.0153 41.438 36.0153 42.0312V50.9688C36.0153 51.562 35.5483 52.0416 34.9708 52.0416C34.3932 52.0416 33.9262 51.562 33.9262 50.9688V42.0312ZM29.9415 39.6187C29.9415 39.0255 30.4085 38.5459 30.9861 38.5459C31.5636 38.5459 32.0306 39.0255 32.0306 39.6187V53.3841C32.0306 53.9773 31.5636 54.457 30.9861 54.457C30.4085 54.457 29.9415 53.9773 29.9415 53.3841V39.6187ZM25.9707 36.9451C25.9707 36.3548 26.4321 35.878 26.9986 35.878C27.5679 35.878 28.0266 36.3548 28.0266 36.9451V56.0577C28.0266 56.6481 27.5651 57.1249 26.9986 57.1249C26.4294 57.1249 25.9707 56.6481 25.9707 56.0577V36.9451Z"
                      fill="#4B465C"
                    />
                    <path
                      d="M37.9109 39.6187C37.9109 39.0255 38.3779 38.5459 38.9555 38.5459C39.533 38.5459 40 39.0255 40 39.6187V53.3841C40 53.9773 39.533 54.457 38.9555 54.457C38.3779 54.457 37.9109 53.9773 37.9109 53.3841V39.6187ZM14 39.6187C14 39.0255 14.467 38.5459 15.0445 38.5459C15.6221 38.5459 16.0891 39.0255 16.0891 39.6187V53.3841C16.0891 53.9773 15.6221 54.457 15.0445 54.457C14.467 54.457 14 53.9773 14 53.3841V39.6187ZM18.0013 36.9451C18.0013 36.3548 18.4627 35.878 19.0292 35.878C19.5985 35.878 20.0572 36.3548 20.0572 36.9451V56.0577C20.0572 56.6481 19.5957 57.1249 19.0292 57.1249C18.46 57.1249 18.0013 56.6481 18.0013 56.0577V36.9451ZM21.9694 34.0728C21.9694 33.4797 22.4364 33 23.0139 33C23.5915 33 24.0585 33.4797 24.0585 34.0728V58.9272C24.0585 59.5203 23.5915 60 23.0139 60C22.4364 60 21.9694 59.5203 21.9694 58.9272V34.0728ZM33.9262 42.0312C33.9262 41.438 34.3932 40.9584 34.9708 40.9584C35.5483 40.9584 36.0153 41.438 36.0153 42.0312V50.9688C36.0153 51.562 35.5483 52.0416 34.9708 52.0416C34.3932 52.0416 33.9262 51.562 33.9262 50.9688V42.0312ZM29.9415 39.6187C29.9415 39.0255 30.4085 38.5459 30.9861 38.5459C31.5636 38.5459 32.0306 39.0255 32.0306 39.6187V53.3841C32.0306 53.9773 31.5636 54.457 30.9861 54.457C30.4085 54.457 29.9415 53.9773 29.9415 53.3841V39.6187ZM25.9707 36.9451C25.9707 36.3548 26.4321 35.878 26.9986 35.878C27.5679 35.878 28.0266 36.3548 28.0266 36.9451V56.0577C28.0266 56.6481 27.5651 57.1249 26.9986 57.1249C26.4294 57.1249 25.9707 56.6481 25.9707 56.0577V36.9451Z"
                      fill="white"
                      fill-opacity="0.2"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_229_11671">
                      <rect width="54" height="61" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <h3
                  class="align-items-center font-weight-bolder my-1 count-class"
                >
                  Music
                </h3>
                <!-- {{data && data.length>0 ?data[0].section :''}} -->
                <b-badge
                  @click="openAudio()"
                  class="cursor-pointer title-settings"
                  variant="light-primary"
                >
                  See the Audio
                </b-badge>
              </div>
            </b-card>
          </b-col>
          <b-col
            md="6"
            lg="4"
            class="d-flex justify-content-center align-items-center"
          >
            <b-card
              class="text-center settings-icon"
            >
              
              <div
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <img src="../../../../../public/workout2.svg" />
                <div
                v-if="isLoading"
                style="height: 20px"
                class="d-flex justify-content-center align-items-center my-1"
              >
                <b-spinner label="Loading..."></b-spinner>
              </div>

                <h3 v-else
                  class="align-items-center font-weight-bolder my-1 count-class"
                >
                  {{ data.workout }}
                </h3>
                <!-- {{data && data.length>0 ?data[0].section :''}} -->
                <b-badge
                  @click="openWorkout()"
                  class="cursor-pointer title-settings"
                  variant="light-primary"
                >
                  Workout
                </b-badge>
              </div>
            </b-card>
          </b-col>



          <b-col
            md="6"
            lg="4"
            class="d-flex justify-content-center align-items-center"
          >
            <b-card
              class="text-center settings-icon"
            >
              
              <div
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <img src="../../../../../public/preworkout.svg" />
                <div
                v-if="isLoading"
                style="height: 20px"
                class="d-flex justify-content-center align-items-center my-1"
              >
                <b-spinner label="Loading..."></b-spinner>
              </div>

                <h3 v-else
                  class="align-items-center font-weight-bolder my-1 count-class"
                >
                  {{ data.predesign }}
                </h3>
                <!-- {{data && data.length>0 ?data[0].section :''}} -->
                <b-badge
                  @click="$router.push('/settings/predesignworkout')"
                  class="cursor-pointer title-settings"
                  variant="light-primary"
                >
                 Predesigned Workout Plan
                </b-badge>
              </div>
            </b-card>
          </b-col>

          <b-col
            md="6"
            lg="4"
            class="d-flex justify-content-center align-items-center"
          >
            <b-card
              class="text-center settings-icon"
            >
              
              <div
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <!-- <img src="../../../../../public/preworkout.svg" /> -->
                <feather-icon
                icon="GridIcon"
                size="50"
                />
                <div
                v-if="isLoading"
                style="height: 20px"
                class="d-flex justify-content-center align-items-center my-1"
              >
                <b-spinner label="Loading..."></b-spinner>
              </div>

                <h3 v-else
                  class="align-items-center font-weight-bolder my-1 count-class"
                >
                  {{ data.categories }}
                </h3>
                <!-- {{data && data.length>0 ?data[0].section :''}} -->
                <b-badge
                  @click="$router.push('/settings/categories')"
                  class="cursor-pointer title-settings"
                  variant="light-primary"
                >
                Categories
                </b-badge>
              </div>
            </b-card>
          </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BBadge,
  BButton,
  BRow,
  BCol,
  BSpinner,
} from "bootstrap-vue";
// import FaqQuestionAnswer from './FaqQuestionAnswer.vue'
import axios from "axios";

export default {
  components: {
    BBadge,
    BCard,
    BRow,
    BCol,
    BSpinner,
    BCardBody,
    BButton,
  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      faqData: {},
      showExtraContent: false,
      ShowContent: false,
      AddContent: false,
      data: [],
      user: [],
      trainer: [],
      isLoading: false,
      clientTotal: [],
      WorkoutTotal: [],
    };
  },
  mounted() {
    this.getCount();
  },
  methods: {
    async getCount() {
      this.isLoading = true;
      await axios({
        method: "GET",
        url: `${this.baseApi}getCounts`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((response) => {
          this.data = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => console.log(error, "error"));
    },
    openHideVideo() {
      this.$router.push({ path: `/settings/allvideos` });
    },
    openSuspendedClient() {
      this.$router.push({ path: `/settings/suspendedclient` });
    },
    openSuspendedTrainer() {
      this.$router.push({ path: `/settings/suspendedtrainer` });
    },
    openAudio() {
      this.$router.push({ path: `/settings/audio` });
    },
    openWorkout() {
      this.$router.push({ path: `/settings/workout` });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";

.settings-icon{
  width: 382.67px;
  height: 260px;
  border-radius: 6px;
  border: 1px solid #dbdade;
  gap: 16px;
 
}
.settings-icon .card-body{
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-settings{
  font-size: 15px;
}
.count-class{
  font-size: 18px;
  font-family: 'Public Sans', sans-serif
}
</style>
